<template>
  <div>
    <Navbar />
    <v-main class="main">
      <v-container fluid>
        <transition name="slide" mode="out-in">
          <router-view></router-view> 
        </transition>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Navbar from '../components/Navbar-comp.vue';
export default {
components: {
    Navbar,
},
  data: () => ({
  }),
}
</script>

<style scoped>
  .slide-enter, .slide-leave-to {
    transform: translate(-50px);
    opacity: 0;
  }

  .slide-enter-active, .slide-leave-active {
    transition: all 0.3s;
  }
  
</style>

